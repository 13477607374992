import { ChildrenItems, Permission } from './../models/menu-items';
import { environment } from './../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Menu } from '../models/menu-items';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  url: string = '';
  public listadoMenu: Menu[] = [];

  httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('aslan')
  });

  constructor(private httpClient: HttpClient) { 
    this.url = environment.urlApi;
  }

  getListMenu(role: number){
    return this.httpClient.post(this.url +'/menu', {'role_id': role}, {headers: this.httpHeaders});
  }

  setListadoMenu(resultado: Menu[]){
    this.listadoMenu = resultado;
  }

  getPermissionActions(menuUrl: string, submenu: string, permiso: string, listado: Menu[]){
    let menues: Menu[];
    let subMenues: ChildrenItems[] | any;
    let permissions: Permission[];
    
    menues = listado;
    try {
      const result = menues.filter(menu => menu.state === menuUrl);
      if (result.length == 1) {
        subMenues = result[0]['children'];
        const resulSub = subMenues.filter((sbMenu: { state: string; }) => sbMenu.state === submenu);
        if (resulSub.length) {
          permissions = resulSub[0]['permission'];
          const resultPer = permissions.filter(per => per.name === permiso);
          if (resultPer.length) {
            return true;
          } else {
            return false;
          }
        } else {
          //console.log('No hay submenues coincidentes');
          return false;
        }
      } else if (result.length > 1){
        for (let index = 0; index < result.length; index++) {
          subMenues = result[index]['children'];
          const resulSub = subMenues.filter((sbMenu: { state: string; }) => sbMenu.state === submenu);
          if (resulSub.length) {
            permissions = resulSub[0]['permission'];
            const resultPer = permissions.filter(per => per.name === permiso);
            if (resultPer.length) {
              index = result.length;
              return true;
            } else {
              //return false;
            }
          } else {
            //console.log('No hay submenues coincidentes');
            //return false;
          }  
        }
        return false;
      } else {
        //console.log('No hay menues coincidentes');
        return false;
      }
    } catch (error) {
      
    }

    return false;
    
  }

}
