import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url: string = '';

  httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private router: Router, private httpClient: HttpClient) { 
    this.url = environment.urlApi;
  }

  setToken(value: any){
    localStorage.setItem('aslan', value);
  }

  getToken(){
    if (localStorage.getItem('aslan') != null) {
      return true;
    }
    return false;
  }

  logIn(form: any){
    return this.httpClient.post(this.url +'/login', form, {headers: this.httpHeaders});
  }

  forgotPass(form: any){
    return this.httpClient.post(this.url +'/forgot', form, {headers: this.httpHeaders});
  }

  resetPassword(form: any){
    return this.httpClient.post(this.url +'/reset-password', form, {headers: this.httpHeaders});
  }

  logOut(){
    localStorage.removeItem('aslan');
    localStorage.removeItem('urlMain');
    localStorage.removeItem('usuario');
    localStorage.removeItem('vendedor');
    this.router.navigate(['/authentication/login']);
  }
}
