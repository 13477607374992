import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ViewDeudores } from '../../models/view-deudores';

import * as moment from 'moment';

@Component({
  selector: 'app-information-client',
  templateUrl: './information-client.component.html',
  styleUrls: ['./information-client.component.scss']
})
export class InformationClientComponent implements OnInit {

  public page_title: string;
  public clientsDetail: ViewDeudores[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.page_title = "Información Cliente(s)"; 
  }

  ngOnInit(): void {
    this.initValues();
  }

  convertToDateDMY(fecha: string) {
    if (fecha == null) {
      return '';
    }
    return moment(fecha, 'YYYY-MM-DD').format('DD/MM/YYYY') ?? '';
  }

  initValues(): void {
    if (this.data.element) {
      this.clientsDetail = this.data.element;
    }
  }

}
