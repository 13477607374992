
import { MatTableDataSource } from '@angular/material/table';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { Lval } from './../../models/lval';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lval-modal',
  templateUrl: './lval-modal.component.html',
  styleUrls: ['./lval-modal.component.scss']
})
export class LvalModalComponent implements OnInit {
  
  public listaValores: Lval[] = [];
  public dataSource: MatTableDataSource<Lval> = new MatTableDataSource<Lval>([]);

  displayedColumns = ['actions', 'codigo', 'descripcion'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(private dialogRef: MatDialogRef<LvalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.listaValores = data.lval ?? [];
  }

  ngOnInit(): void {
    this.lvalMatSource();
  }

  lvalMatSource() {
    this.dataSource = new MatTableDataSource<Lval>(this.listaValores);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSelected(element: any){
    this.dialogRef.close(<Lval>element);
  }

}
