<div class="page_title">
  <button mat-button class="close-button btn-min" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="primary">close</mat-icon>
  </button>
  <span class="titulo">{{ page_title }}</span>
  <span class="subtitulo"> {{ this.data.title }} </span>
</div>
<hr />
<mat-tab-group animationDuration="0ms">
  <!-- Iterate over your list and generate tabs -->
  <mat-tab *ngFor="let clientDetail of clientsDetail" [label]="clientDetail.primer_nombre+' '+clientDetail.primer_apellido">
    <mat-card-content class="p-24">
      <!-- Content for each tab -->
      <div fxLayout="row wrap" class="detalle-apartamento">
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">No. Teléfono </div>
          <div class="data-form">{{ clientDetail.telefono }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">No. Celular </div>
          <div class="data-form">{{ clientDetail.celular }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Email</div>
          <div class="data-form">{{ clientDetail.email }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Identificación</div>
          <div class="data-form">{{ clientDetail.dpi }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Nit</div>
          <div class="data-form">{{ clientDetail.nit }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Fecha Nacimiento</div>
          <div class="data-form">{{ convertToDateDMY(clientDetail.fecha_nacimiento) }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Nacionalidad</div>
          <div class="data-form">{{ clientDetail.nacionalidad_descripcion }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Estado Civil</div>
          <div class="data-form">{{ clientDetail.estado_civil_descripcion }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Ocupación</div>
          <div class="data-form">{{ clientDetail.ocupacion_descripcion }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Tipo Cliente</div>
          <div class="data-form">{{ clientDetail.clase_cliente_descripcion }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Actividad Económica</div>
          <div class="data-form">{{ clientDetail.tipo_cliente_descripcion }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Empresa donde Labora</div>
          <div class="data-form">{{ clientDetail.empresa_labora }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Empresa No. Teléfono</div>
          <div class="data-form">{{ clientDetail.empresa_contacto }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Ingreso Mensual</div>
          <div class="data-form">{{ clientDetail.ingreso_mensual | currency:'USD': data.moneda + ' '}} </div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Otros Ingresos</div>
          <div class="data-form">{{ clientDetail.otros_ingresos | currency:'USD': data.moneda + ' ' }} </div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Egreso Mensual</div>
          <div class="data-form">{{ clientDetail.egreso_mensual | currency:'USD': data.moneda + ' ' }} </div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Datos Contacto Alterno</div>
          <div class="data-form">{{ clientDetail.persona_contacto }}</div>
        </div>
        <div
          fxFlex.gt-lg="50"
          fxFlex.gt-md="50"
          fxFlex.gt-xs="50"
          fxFlex="50"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Teléfono Contacto Alterno</div>
          <div class="data-form">{{ clientDetail.tel_contacto }} </div>
        </div>
      
        <div
          fxFlex.gt-lg="100"
          fxFlex.gt-md="100"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="detalle-apartamento__item"
        >
          <div class="data-form-label">Listado de Direcciones</div>
        </div>
      
        <div
          fxFlex.gt-lg="100"
          fxFlex.gt-md="100"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="detalle-apartamento__item"
        >
          <table class="table">
            <thead>
              <th>Tipo Dirección</th>
              <th>Dirección</th>
            </thead>
            <tbody>
              <tr *ngFor="let direc of clientDetail.direcciones">
                <td>{{ direc.tipo_direccion_descripcion }}</td>
                <td>{{ direc.direccion }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-tab>
</mat-tab-group>




