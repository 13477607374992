
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

import { UserLogin } from '../models/user-login';
import { SellerLogin } from '../models/seller-login';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: string = '';

  userLogon: UserLogin = {
    id: 0,
    name: '',
    email: '',
    role: 0,
    role_name: ''
  };

  sellerLogon: SellerLogin = {
    id: 0,
    nombre: '',
    apellido: '',
    email: '',
  };

  httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private router: Router, private httpClient: HttpClient) { 
    this.url = environment.urlApi;
  }

  setUserLogged(user: UserLogin) {
    this.userLogon = user ?? '';
    localStorage.setItem('usuario', JSON.stringify(this.userLogon));
  }

  getUserLogged(): UserLogin {
    let valor: string = localStorage.getItem('usuario') ?? '';
    this.userLogon = JSON.parse(valor) as UserLogin; 
    return this.userLogon;
  }

  setUserSellerLogged(seller: SellerLogin) {
    this.sellerLogon = seller ?? '';
    localStorage.setItem('vendedor', JSON.stringify(this.sellerLogon));
  }

  getUserSellerLogged(): SellerLogin {
    let valor: string = localStorage.getItem('vendedor') ?? '';
    this.sellerLogon = JSON.parse(valor) as SellerLogin; 
    return this.sellerLogon;
  }

  setUrlMainRedirect(url: string = '/') {
    localStorage.setItem('urlMain', url);
  }

  getUrlMain() {
    return localStorage.getItem('urlMain') ?? '/';
  }

}
