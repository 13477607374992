import { AppBlankComponent } from './layouts/blank/blank.component';
import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'administration',
                loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
            }
        ]
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'sales-administration',
                loadChildren: () => import('./sales-administration/sales-administration.module').then(m => m.SalesAdministrationModule)
            }
        ]
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'seller',
                loadChildren: () => import('./seller/seller.module').then(m => m.SellerModule)
            }
        ]
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'accounting',
                loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule)
            }
        ]
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'legal',
                loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
            }
        ]
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'system-counter',
                loadChildren: () => import('./system-counter/counter.module').then(m => m.CounterModule)
            }
        ]
    },

  
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: '',
                redirectTo: '/login',
                pathMatch: 'full'
            },
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'authentication/404'
    }
];
