import { MenuService } from './../../services/menu.service';
import { AuthService } from './../../../authentication/services/auth-service.service';
import { UserService } from './../../../authentication/services/user.service';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';


import { UserLogin } from 'src/app/authentication/models/user-login';
import { Menu } from '../../models/menu-items';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }

  public user: UserLogin;
  menuList: Menu[] = [];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private userService: UserService,
    private menuService: MenuService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.user = this.userService.getUserLogged();
  }
  ngOnInit(): void {
    this.getListMenu();
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logOutSystem(){
    this.authService.logOut();
  }

  getListMenu(){
    this.menuService.getListMenu(this.user.role).subscribe( (response: any) => {
      this.menuList = response.data;
      this.menuService.setListadoMenu(this.menuList);
    });
  }
}
