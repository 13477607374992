<div class="page_title">
  <button mat-button class="close-button btn-min" [mat-dialog-close]="true">
    X
  </button>
  <span class="titulo"> {{ data.titulo }} </span>
</div>

<div fxLayout="row wrap" class="align-items-center course-filters" style="width: 100%;">
  <div fxFlex.gt-md="100" fxFlex.gt-lg="100" fxFlex="100">
    <mat-form-field>
      <mat-label>Búsqueda</mat-label>
      <input matInput (keyup)="applyFilterData($event)" placeholder="Búsqueda Lista Valores" #input>
    </mat-form-field>
  </div>
</div>

<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="responsive-table">
      <mat-table [dataSource]="dataSource" matSort>

        <!-- codigo Column -->
        <ng-container matColumnDef="codigo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Código </mat-header-cell>
          <mat-cell *matCellDef="let element"> <span class="header-label">Código:</span> {{element.codigo}} </mat-cell>
        </ng-container>

        <!-- descripcion Column -->
        <ng-container matColumnDef="descripcion">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
          <mat-cell *matCellDef="let element"> <span class="header-label">Descripción:</span> {{element.descripcion | uppercase }} </mat-cell>
        </ng-container>

        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="actions"> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let element" class="actions">
            <button mat-flat-button color="primary" (click)="onSelected(element)" matTooltip="Seleccionar"
              matTooltipClass="tooltip-red">
              Elegir
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>

